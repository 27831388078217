import { DEFAULT_LANG, LANG } from './constants/lang';
import { bwTypesMap, subTypes, types } from './constants/document';
import { ROUTES } from './constants/routes';
import _ from 'lodash';
import tippy from 'tippy.js';

export function compose(...funcs) {
  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)));
}

export function scrollTo(top) {
  window.scroll({ top, left: 0, behavior: 'smooth' });
}

export function getLangFromPath(path) {
  if (path === '/') {
    return DEFAULT_LANG.toLowerCase();
  }

  const probableLangFromPath = path.split('/')[1];

  if (Object.values(LANG).includes(probableLangFromPath.toUpperCase()))
    return probableLangFromPath;

  return DEFAULT_LANG.toLowerCase();
}

export function getLabelFromLang(label = {}, lang = 'EN', fallback = 'EN') {
  //client asked to remove default author from Case File (Story Line) on Hebrew lang
  if (typeof label === 'object' && lang === 'he') {
    if (
      label['HE'] &&
      label['HE'].STORY_LINE &&
      label['HE'].STORY_LINE === 'הרב אריה ליכטנשטיין'
    ) {
      label['HE'].STORY_LINE = '';
    }
  }

  return typeof label === 'string'
    ? label
    : label[lang.toUpperCase()] || label[fallback.toUpperCase()];
}

export function getTextAlign(lang, size) {
  const items = [
    'text',
    size,
    lang && lang.toLowerCase() === 'he' ? 'right' : 'left',
  ];

  return items.filter(o => o).join('-');
}

export function getFieldName(key, lang = null, path = null) {
  let name = '';
  if (path) {
    name += `${path}.`;
  }
  return lang ? `${name}lang.${lang}.${key}` : `${name}${key}`;
}

export function fillHierarchyOptions(options, hierarchyItems, lang, arrKey) {
  hierarchyItems.forEach(item => {
    options.push({
      prefix:
        item.hierarchyLevel === 1
          ? ''
          : `${'-'.repeat(item.hierarchyLevel - 1)} `,
      label: item.label,
      value: item.id,
    });
    if (item[arrKey]) {
      fillHierarchyOptions(options, item[arrKey], lang, arrKey);
    }
  });

  return options;
}

export function getSearchDocumentLink(doc, lang = null) {
  let typePart;
  let idPart;
  switch (doc.type) {
    case types.AUDIO: {
      typePart = ROUTES.AUDIO_ITEM_PARAM;
      idPart = doc.id;
      break;
    }
    case types.DOCUMENT: {
      typePart = ROUTES.DOCUMENT_PARAM;
      idPart = doc.id;
      break;
    }
    case types.ARTICLE: {
      typePart = ROUTES.ARTICLE_PARAM;
      idPart = doc.id;
      break;
    }
    case types.BUSINESS_WEEKLY: {
      typePart = ROUTES.BUSINESS_WEEKLY_ITEM_PARAM;
      if (bwTypesMap[doc.subType] === subTypes.BUSINESS_WEEKLY.storyLine) {
        idPart = `${doc.bwStoryLine && doc.bwStoryLine.id}?selected=0`;
      }
      if (bwTypesMap[doc.subType] === subTypes.BUSINESS_WEEKLY.hotline) {
        idPart = `${doc.bwHotline && doc.bwHotline.id}?selected=1`;
      }
      if (bwTypesMap[doc.subType] === subTypes.BUSINESS_WEEKLY.moneyMatters) {
        idPart = `${doc.bwMoneyMatters && doc.bwMoneyMatters.id}?selected=2`;
      }
      break;
    }
    default:
      typePart = '';
  }
  return lang ? `/${lang}${typePart}${idPart}` : `${typePart}${idPart}`;
}

export function partition(array, isValid) {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []],
  );
}

export function getTreeFromFlatData({
  flatData,
  getKey = node => node.id,
  getParentKey = node => node.parentId,
  rootKey = '0',
}) {
  if (!flatData) {
    return [];
  }

  const childrenToParents = {};
  flatData.forEach(child => {
    const parentKey = getParentKey(child);

    if (parentKey in childrenToParents) {
      childrenToParents[parentKey].push(child);
    } else {
      childrenToParents[parentKey] = [child];
    }
  });

  if (!(rootKey in childrenToParents)) {
    return [];
  }

  const trav = parent => {
    const parentKey = getKey(parent);
    if (parentKey in childrenToParents) {
      return {
        ...parent,
        children: childrenToParents[parentKey].map(child => trav(child)),
      };
    }

    return {
      ...parent,
      children: [{ id: `${parent.id}-noChildren`, title: '' }],
    };
  };

  return childrenToParents[rootKey].map(child => trav(child));
}

export function findCategoryTypeById(id, categoryTypes) {
  let foundItem;
  categoryTypes.forEach(categoryType => {
    if (foundItem) return;
    if (categoryType.id === id) {
      foundItem = categoryType;
    } else {
      if (categoryType.categoryTypes)
        foundItem = findCategoryTypeById(id, categoryType.categoryTypes);
    }
  });
  return foundItem;
}

export function tippyContent(htmlString) {
  const hasFootnotes = /class="footnote"/.test(htmlString, 'ig');
  if (!hasFootnotes) return htmlString;
  const dividedString = htmlString.split(/<hr class="[\s\S]*?">/); //element could be <hr class="my-hr"> or <hr class="my-hr ql-direction-rtl">

  const beforeDevider = dividedString[0];
  const afterDevider = dividedString[1];

  const div = document.createElement('div');
  div.innerHTML = beforeDevider;
  const fDiv = document.createElement('div');
  fDiv.innerHTML = afterDevider;
  const footnotes = _.uniqBy(
    Array.from(div.querySelectorAll('.footnote')),
    'id',
  );

  footnotes.forEach(item => {
    const contentId = item.getAttribute('data-ref');
    const id = item.getAttribute('id');
    if (item._tippy) {
      item._tippy.destroy();
    }

    setTimeout(
      () =>
        tippy(`#${id}`, {
          content: fDiv.querySelector(contentId).innerHTML,
          allowHTML: true,
          interactive: true,
          theme: 'light-border',
        }),
      0,
    );
  });

  return htmlString;
}

export function tableTime(time) {
  const curDate = new Date(time).toDateString();
  const retDate = /invalid date/gi.test(curDate) ? 'N/A' : curDate;
  return retDate;
}
