import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  Form,
  Row,
  Col,
  TabContent,
  TabPane,
  Button,
  Card,
  CardBody,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import PdfViewer from '../PdfViewer';
import InputRedux from '../Input/InputRedux';
import FieldFormGroup from '../FieldFormGroup';
import FormActions from '../FormActions';
import LanguageTabs from '../LanguageTabs';
import RelatedForm from '../RelatedForm';
import CategoryField from '../CategoryField';
import CategoryCopyButtons from '../CategoryCopyButtons';

import { statuses } from '../../constants/document';
import { FILE_URL } from '../../constants/files';
import { types } from '../../constants/document';
import { ROUTES } from '../../constants/routes';

const KEY_FORM_OPTIONS = {
  status: {
    label: 'Status',
    type: 'select',
    icon: 'button-power',
    options: [statuses.DRAFT, statuses.PUBLISHED],
  },
  title: {
    label: 'Name',
    type: 'text',
    icon: 'collection',
  },
  file: {
    label: 'File',
    type: 'file',
  },
  watermark: {
    label: 'Apply watermark',
    type: 'checkbox',
    notWrap: true,
  },
  publishedAt: {
    label: 'Publish Date',
    type: 'date',
    icon: 'calendar-grid-58',
  },
  categories: {
    type: 'multiSelect',
    icon: 'tag',
    placeholder: 'Select category',
  },
  description: {
    label: 'Description',
    type: 'textarea',
    icon: 'align-left-2',
    rows: 8,
  },
};

export default class DocumentForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabs: props.defaultLang,
      fromProps: {},
    };

    this.fileChanged = this.fileChanged.bind(this);
    this.langChanged = this.langChanged.bind(this);
    this.relatedChanged = this.relatedChanged.bind(this);
    this.onCategoryCopy = this.onCategoryCopy.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { defaultLang } = nextProps;
    const newState = { fromProps: state.fromProps };
    if (state.fromProps.defaultLang !== defaultLang) {
      newState.tabs = defaultLang;
      newState.fromProps.defaultLang = defaultLang;
    }

    return newState;
  }

  componentDidMount() {
    const {
      getCategoriesRelations,
      getCategoryTypesList,
      getListByDoctype,
    } = this.props;
    getCategoriesRelations();
    getCategoryTypesList();
    getListByDoctype('DOCUMENT');
  }

  relatedChanged(related) {
    const { change } = this.props;
    change(`related`, related);
  }

  langChanged(lang) {
    this.setState({ tabs: lang });
  }

  onCategoryCopy(fromLang, toLang) {
    const { change, langData } = this.props;

    const categories = langData[fromLang].categories;

    change(`lang.${toLang}.categories`, categories);
  }

  fileChanged(fileName) {
    const { change, defaultLang, langValues } = this.props;
    if (!langValues || !langValues[defaultLang].title) {
      change(`lang.${defaultLang}.title`, fileName);
    }
  }

  renderField(key, lang = null) {
    const { saving } = this.props;
    const name = lang ? `lang.${lang}.` + key : key;
    const { icon, type, label, notWrap, ...rest } = KEY_FORM_OPTIONS[key];
    const isHE = lang === 'he';

    return (
      <FieldFormGroup icon={icon} label={label} lang={lang} wrap={!notWrap}>
        <Field
          name={name}
          type={type}
          disabled={saving}
          onFocus={() => this.setState({ [`focused_${key}`]: true })}
          onBlur={() => this.setState({ [`focused_${key}`]: false })}
          component={InputRedux}
          dir={`${isHE ? 'rtl' : 'ltr'}`}
          {...rest}
        />
      </FieldFormGroup>
    );
  }

  removeCategory = (id, docType, docTypeLang) => e => {
    const { change, langData } = this.props;
    const filteredCategories = langData[docTypeLang].categories.filter(
      item => item.id !== id,
    );
    change(`lang.${docTypeLang}.categories`, filteredCategories);
  };

  renderFile(watermark = false) {
    const { file } = this.props;

    if (watermark) {
      if (!file || !file.watermarkUrl) return null;
      return (
        <PdfViewer
          classes="py-3"
          scale={0.7}
          newTabLink={true}
          url={`${FILE_URL}${file.watermarkUrl}`}
        />
      );
    }

    if (!file || !file.url) return <div className="my-8 py-4" />;
    return (
      <PdfViewer
        classes="py-3"
        scale={0.7}
        url={`${FILE_URL}${file.url}`}
        newTabLink={true}
      />
    );
  }

  render() {
    const {
      saving,
      handleSubmit,
      onDelete,
      langList,
      isNew,
      formId,
      addForm,
      relatedAudio,
      relatedArticle,
      relatedBW,
      relatedValues,
      categoriesRelations,
      change,
      langData,
    } = this.props;

    const relatedCategories = categoriesRelations.filter(
      item => item.doctype === 'DOCUMENT',
    );

    return (
      <Form onSubmit={handleSubmit} role="form">
        <Row className="justify-content-between py-4 mb-4 border-bottom">
          <Col sm={4}>
            <LanguageTabs onChange={this.langChanged} />
          </Col>
          <Col className="text-right" sm={6}>
            {formId ? (
              <Link
                className="btn btn-default"
                to={`${ROUTES.ADMIN_FORMS_ITEM_PARAM}${formId}`}
              >
                Edit form
              </Link>
            ) : (
              <Button type="button" color="default" onClick={addForm}>
                Add form
              </Button>
            )}
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <TabContent activeTab={'tabs-' + this.state.tabs}>
              {langList.map(lang => {
                return (
                  <TabPane
                    key={`tab-${lang.identifier}`}
                    tabId={`tabs-${lang.identifier}`}
                  >
                    <Row>
                      <Col>{this.renderField('title', lang.identifier)}</Col>
                    </Row>
                  </TabPane>
                );
              })}
            </TabContent>
            <Row noGutters className="justify-content-between">
              <Col sm={8}>
                <label className="form-control-label">PDF</label>
                <Field
                  name="file"
                  type={KEY_FORM_OPTIONS.file.type}
                  component={InputRedux}
                  filechanged={this.fileChanged}
                  accept="application/pdf"
                  fileid="document-file-input"
                  disabled={saving}
                />
              </Col>
              <Col sm={3}>{this.renderField('watermark')}</Col>
            </Row>
            <Row noGutters className="justify-content-between">
              <Col sm={12}>
                <TabContent activeTab={'tabs-' + this.state.tabs}>
                  {langList.map(lang => {
                    return (
                      <TabPane
                        key={`tab-${lang.identifier}`}
                        tabId={`tabs-${lang.identifier}`}
                      >
                        <Row>
                          <Col>
                            {this.renderField('description', lang.identifier)}
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col md={6}>{this.renderField('publishedAt')}</Col>
              <Col md={6}>{this.renderField('status')}</Col>
            </Row>
            <RelatedForm
              changeRelated={this.relatedChanged}
              relatedValues={relatedValues}
              relatedDefault={{
                [types.ARTICLE]: relatedArticle,
                [types.AUDIO]: relatedAudio,
                [types.BUSINESS_WEEKLY]: relatedBW,
              }}
              relatedFields={[
                types.ARTICLE,
                types.AUDIO,
                types.BUSINESS_WEEKLY,
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.renderFile()}</Col>
          <Col>{this.renderFile(true)}</Col>
        </Row>
        {relatedCategories.length > 0 && (
          <Row>
            <Col>
              <Card className="shadow mb-6">
                <CardBody className="bg-secondary">
                  <TabContent activeTab={'tabs-' + this.state.tabs}>
                    {langList.map((lang, i) => {
                      return (
                        <TabPane
                          key={`tab-${lang.identifier}`}
                          tabId={`tabs-${lang.identifier}`}
                        >
                          <Row key={lang.identifier}>
                            <Col>
                              <Card className="shadow">
                                <CardBody className="bg-secondary">
                                  <div className="d-flex">
                                    <h3 className="text-muted mt-2 mr-4">
                                      Categories {lang.identifier.toUpperCase()}
                                    </h3>
                                    <CategoryCopyButtons
                                      langList={langList}
                                      lang={lang.identifier}
                                      onClick={this.onCategoryCopy}
                                    />
                                  </div>
                                  {relatedCategories.length > 0 &&
                                    relatedCategories.map(item => (
                                      <CategoryField
                                        key={item.id}
                                        data={item}
                                        change={change}
                                        docTypeLang={lang.identifier}
                                        selectedCategories={
                                          (langData &&
                                            langData[lang.identifier] &&
                                            langData[lang.identifier]
                                              .categories) ||
                                          []
                                        }
                                        removeCategory={this.removeCategory}
                                      />
                                    ))}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      );
                    })}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col className="text-right mt-4">
            <FormActions saving={saving} isNew={isNew} onDelete={onDelete} />
          </Col>
        </Row>
      </Form>
    );
  }
}
