export const types = {
  SEMINARS: 'SEMINARS',
  CHABUROS: 'CHABUROS',
  SHIURIM: 'SHIURIM',
};

export const singleTypes = {
  SEMINAR: 'SEMINAR',
  CHABURA: 'CHABURA',
  SHIUR: 'SHIUR',
  TOPIC: 'TOPIC',
  SHULCHAN_ARUCH: 'SHULCHAN_ARUCH',
  BHI_SEMINAR: 'BHI_SEMINAR',
};

export const singleTypeOptions = [
  { value: '', label: 'All' },
  { value: singleTypes.SEMINAR, label: 'Seminar' },
  { value: singleTypes.CHABURA, label: 'Chabura' },
  { value: singleTypes.SHIUR, label: 'Shiur' },
  { value: singleTypes.TOPIC, label: 'Topic' },
  { value: singleTypes.SHULCHAN_ARUCH, label: 'Shulchan Aruch' },
  { value: singleTypes.BHI_SEMINAR, label: 'BHI Seminar' },
];

export const typeColors = {
  [types.SEMINARS]: 'default',
  [types.CHABUROS]: 'info',
  [types.SHIURIM]: 'success',

  [singleTypes.SEMINAR]: 'default',
  [singleTypes.CHABURA]: 'info',
  [singleTypes.SHIUR]: 'success',
};
