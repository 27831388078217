export const articleTypes = {
  NEWSLETTERS: 'NEWSLETTERS',
  CLASSROOM_MATERIALS: 'CLASSROOM_MATERIALS',
  OTHER: 'OTHER',
};

export const articleTypeOptions = [
  { value: '', label: 'All' },
  { value: articleTypes.NEWSLETTERS, label: 'Newsletters' },
  { value: articleTypes.CLASSROOM_MATERIALS, label: 'Classroom Materials' },
  { value: articleTypes.OTHER, label: 'Other' },
];
