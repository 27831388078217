import React, { Component } from 'react';
import LocalizedLink from '../../components/LocalizedLink';

import { Container, Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { getLabelFromLang } from '../../utils';

export default class ClassroomMaterials extends Component {
  render() {
    const { lang } = this.props;
    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.MATERIALS}>
                  {getLabelFromLang(LINK_LABELS.MATERIALS, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {getLabelFromLang(LINK_LABELS.CLASSROOM_MATERIALS, lang)}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.CLASSROOM_MATERIALS, lang)}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container>
            <Row className="align-items-stretch">
              <Col lg={12} xs={12}>
                <p className="h2 py-6">Coming soon</p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
