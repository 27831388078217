import React, { Component } from 'react';

import LocalizedLink from '../../components/LocalizedLink';
import PdfViewer from '../../components/PdfViewer';
import RelatedPublications from '../../components/RelatedPublications';
import RelatedAudio from '../../components/RelatedAudio';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { TRANSLATIONS } from '../../constants/translations';
import { FILE_URL } from '../../constants/files';
import Button from 'reactstrap/es/Button';
import SubmissionModal from './SubmissionModal';
import ShareBtn from '../../components/ShareBtn';
import { getLabelFromLang } from '../../utils';

export default class DocumentPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      fromProps: {},
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { submissionModalOpened } = nextProps;
    const newState = {
      fromProps: {
        submissionModalOpened: state.fromProps.submissionModalOpened,
      },
    };

    if (submissionModalOpened !== state.fromProps.submissionModalOpened) {
      newState.fromProps.submissionModalOpened = submissionModalOpened;
    }

    return newState;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { submissionModalOpened },
    } = this.state;

    const {
      match: {
        params: { id },
        url,
      },
    } = this.props;

    if (prevState.fromProps.submissionModalOpened && !submissionModalOpened) {
      this.toggleModal(false);
    }

    if (url !== prevProps.match.url) {
      this.props.getItem(id);
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.getItem(id);
  }

  toggleModal(open = false) {
    this.setState({ modalIsOpen: open });
  }

  submitForm(data) {
    const { createSubmission } = this.props;
    createSubmission(data);
  }

  render() {
    const { modalIsOpen } = this.state;
    const {
      lang: selectedLang,
      defaultLang,
      showRelated,
      item: { id, lang, file, form, createdAt, type, related },
    } = this.props;
    const langData = lang && (lang[selectedLang] || lang[defaultLang]);
    const defaultLangData = lang && (lang[defaultLang] || {});

    const authorLabel =
      (langData && langData.author) ||
      TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()];

    if (!id)
      return (
        <Container>
          <Row>
            <p className="display-2 pt-2">Shtar not found</p>
          </Row>
        </Container>
      );

    return (
      <>
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, selectedLang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.MATERIALS}>
                  {getLabelFromLang(LINK_LABELS.MATERIALS, selectedLang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.DOCUMENTS}>
                  {getLabelFromLang(LINK_LABELS.DOCUMENTS, selectedLang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {langData.title || defaultLangData.title}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-center">
              <Col lg={8} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {langData.title || defaultLangData.title}
                </h1>
                <p className="text-white h6 lh-120">
                  {new Date(createdAt).toDateString()}
                  {authorLabel && <span> | {authorLabel}</span>}
                </p>
              </Col>
              <Col lg={4} xs={12} className="text-right">
                <LocalizedLink
                  to={ROUTES.DOCUMENTS}
                  className="btn btn-outline-white btn-sm mt-3 mb-3"
                >
                  <span className="btn-inner--text mr-1">
                    {getLabelFromLang(TRANSLATIONS.ALL, selectedLang)}{' '}
                    {getLabelFromLang(TRANSLATIONS.DOCUMENTS, selectedLang)}
                  </span>
                  <span className="btn-inner--icon">
                    <i className="ni ni-bold-right" />
                  </span>
                </LocalizedLink>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pt-4 pb-6 ">
          <Container>
            <Row>
              <Col lg={9}>
                <p>{langData.description}</p>
              </Col>
              <Col lg={9}>
                {file.id && (
                  <PdfViewer
                    url={`${FILE_URL}${
                      file.watermarkUrl ? file.watermarkUrl : file.url
                    }`}
                  />
                )}
              </Col>
              <Col lg={3} className="mt-4 mt-lg-0">
                {file && file.id && (
                  <a
                    href={`${FILE_URL}${
                      file.watermarkUrl ? file.watermarkUrl : file.url
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-cloud-download-95" />
                    </span>
                    <span className="btn-inner--text">
                      {getLabelFromLang(TRANSLATIONS.DOWNLOAD, selectedLang)}{' '}
                      PDF
                    </span>
                  </a>
                )}

                <ShareBtn contentType={type} title={langData.title} />

                {form && form.id ? (
                  <Button
                    onClick={() => this.toggleModal(true)}
                    className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0 mb-4"
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-ui-04" />
                    </span>
                    <span className="btn-inner--text">
                      {form.lang[selectedLang].btnText ||
                        'Request Custom Shtar'}
                    </span>
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Container>
        </section>
        {showRelated && (
          <section className="pt-2 pb-6">
            <Container>
              <hr />
              <Row>
                <Col lg={6}>
                  <RelatedAudio related={related} tags={[]} />
                </Col>
                <Col lg={6}>
                  <RelatedPublications related={related} tags={[]} />
                </Col>
              </Row>
            </Container>
          </section>
        )}

        {form && form.id ? (
          <SubmissionModal
            isOpened={modalIsOpen}
            save={this.submitForm}
            form={form}
            lang={selectedLang}
            defaultLang={defaultLang}
            close={() => this.toggleModal()}
          />
        ) : null}
      </>
    );
  }
}
