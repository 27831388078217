import { ROUTES } from './routes';
import { LANG } from './lang';

export const LINK_LABELS = {
  HOME: {
    [LANG.EN]: 'Home',
    [LANG.HE]: 'דף הבית',
    [LANG.FR]: 'Home',
  },
  BUSINESS_WEEKLY: {
    [LANG.EN]: 'Business Weekly',
    [LANG.HE]: 'משא ומתן',
    [LANG.FR]: 'Business Weekly',
  },
  BUSINESS_WEEKLY_VOLUMES: {
    [LANG.EN]: 'Business Weekly Volumes',
  },
  ARCHIVE: {
    [LANG.EN]: 'Archive',
    [LANG.HE]: 'ארכיון',
    [LANG.FR]: 'Archive',
  },
  ARBITRATION: {
    [LANG.EN]: 'Beis Din',
    [LANG.HE]: 'בוררות',
  },
  SERVICES: {
    [LANG.EN]: 'Business Services',
    [LANG.HE]: 'שירותי המכון',
  },
  CONSULTATION: {
    [LANG.EN]: 'Business Halacha Consultation',
    [LANG.HE]: 'הדרכה',
  },
  HALACHIC_CONTRACTS: {
    [LANG.EN]: 'Halachic Contracts',
  },
  HALACHA_HOTLINE: {
    [LANG.EN]: 'Halacha Hotline',
  },
  MATERIALS: {
    [LANG.EN]: 'Materials',
    [LANG.HE]: 'חומר',
    [LANG.FR]: 'Materiel',
  },
  ARTICLES: {
    [LANG.EN]: 'Articles',
    [LANG.HE]: 'מאמרים',
    [LANG.FR]: 'Articles',
  },
  KUNTRAISIM: {
    [LANG.EN]: 'Kuntraisim',
    [LANG.HE]: 'קונטרסים',
    [LANG.FR]: 'Kuntraisim',
  },
  CLASSROOM_MATERIALS: {
    [LANG.EN]: 'Classroom Materials',
  },
  SHULCHAN_ARUCH: {
    [LANG.EN]: 'Shulchan Aruch',
    [LANG.HE]: 'שלחן ערוך',
    [LANG.FR]: 'Shulchan Aruch',
  },
  PROFILE: {
    [LANG.EN]: 'My profile',
    [LANG.HE]: 'החשבון שלי',
    [LANG.FR]: 'Mon profile',
  },
  NEWSLETTERS: {
    [LANG.EN]: 'Newsletters',
    [LANG.HE]: 'גליונות',
    [LANG.FR]: 'Newsletters',
  },
  AUDIO: {
    [LANG.EN]: 'Shiurim',
    [LANG.HE]: 'שיעורים',
    [LANG.FR]: 'Shiurim',
  },
  DOCUMENTS: {
    [LANG.EN]: 'Shtaros',
    [LANG.HE]: 'מסמכים',
    [LANG.FR]: 'Document',
  },
  HALACHIC_GUIDANCE: {
    [LANG.EN]: 'Halachic guidance',
    [LANG.HE]: 'הדרכה להלכה',
    [LANG.FR]: 'Ligne de conduite halachique',
  },
  ABOUT_US: {
    [LANG.EN]: 'About BHI',
    [LANG.HE]: 'אודותינו',
  },
  CONTACT_US: {
    [LANG.EN]: 'Contact',
    [LANG.HE]: 'התחבר',
  },
  HOTLINE: {
    [LANG.EN]: 'Hotline',
    [LANG.HE]: 'חייג לקו ההלכה',
  },
  HOTLINE_FEEDBACK: {
    [LANG.EN]: 'Hotline',
    [LANG.HE]: 'דעתך חשובה לנו',
  },
  DONATE: {
    [LANG.EN]: 'Donation',
    [LANG.HE]: 'לתרומות',
    [LANG.FR]: 'Don',
  },
  MASA_UMATAN_VOLUMES: {
    [LANG.EN]: 'Masa Umatan Volumes',
  },
  TOPIC: {
    [LANG.EN]: 'Topic',
  },
  BHI_SEMINAR: {
    [LANG.EN]: 'BHI Seminar',
  },
};

export const MAIN_MENU = [
  {
    label: LINK_LABELS.BUSINESS_WEEKLY,
    link: ROUTES.BUSINESS_WEEKLY,
  },
  {
    label: LINK_LABELS.ARBITRATION,
    link: ROUTES.ARBITRATION,
  },
  {
    label: LINK_LABELS.SERVICES,
    link: ROUTES.SERVICES,
    sub: [
      {
        label: LINK_LABELS.CONSULTATION,
        link: ROUTES.CONSULTATION,
      },
      {
        label: LINK_LABELS.HALACHIC_CONTRACTS,
        link: ROUTES.DOCUMENTS,
      },
      {
        label: LINK_LABELS.HALACHA_HOTLINE,
        link: ROUTES.HALACHA_HOTLINE,
      },
    ],
  },
  {
    label: LINK_LABELS.ARCHIVE,
    link: ROUTES.MATERIALS,
    sub: [
      {
        label: LINK_LABELS.AUDIO,
        link: ROUTES.AUDIO,
        sub: [
          {
            label: LINK_LABELS.TOPIC,
            link: ROUTES.TOPIC,
          },
          {
            label: LINK_LABELS.SHULCHAN_ARUCH,
            link: ROUTES.SHULCHAN_ARUCH,
          },
          {
            label: LINK_LABELS.BHI_SEMINAR,
            link: ROUTES.BHI_SEMINAR,
          },
        ],
      },
      {
        label: LINK_LABELS.KUNTRAISIM,
        link: ROUTES.KUNTRAISIM,
        sub: [
          {
            label: LINK_LABELS.CLASSROOM_MATERIALS,
            link: ROUTES.CLASSROOM_MATERIALS,
            comingSoon: true,
          },
          {
            label: LINK_LABELS.BUSINESS_WEEKLY_VOLUMES,
            link: ROUTES.BUSINESS_WEEKLY_VOLUMES,
          },
          {
            label: LINK_LABELS.MASA_UMATAN_VOLUMES,
            link: ROUTES.MASA_UMATAN_VOLUMES,
          },
        ],
      },
    ],
  },
  {
    label: LINK_LABELS.ABOUT_US,
    link: ROUTES.ABOUT_US,
  },
  {
    label: LINK_LABELS.CONTACT_US,
    link: ROUTES.CONTACT_US,
  },
];

export const ADMIN_SIDEBAR_MENU = [
  {
    name: 'Dashboard',
    icon: 'ni ni-shop text-primary',
    path: ROUTES.ADMIN_DASHBOARD,
  },
  {
    name: 'Banner',
    icon: 'ni ni-ungroup text-orange',
    path: ROUTES.ADMIN_BANNER,
  },
  {
    name: 'Business Weekly',
    icon: 'ni ni-email-83 text-default',
    path: ROUTES.ADMIN_BUSINESS_WEEKLY,
  },
  {
    collapse: true,
    name: 'Materials',
    icon: 'ni ni-app text-info',
    state: 'componentsCollapse',
    views: [
      {
        path: ROUTES.ADMIN_AUDIO,
        name: 'Audio',
      },
      {
        path: ROUTES.ADMIN_DOCUMENTS,
        name: 'Documents',
      },
      {
        path: ROUTES.ADMIN_ARTICLES,
        name: 'Articles & Publications',
      },
    ],
  },
  {
    name: 'Categories',
    icon: 'ni ni-chart-pie-35 text-green',
    path: ROUTES.ADMIN_CATEGORIES,
  },
  {
    name: 'Forms',
    icon: 'ni ni-single-copy-04 text-pink',
    path: ROUTES.ADMIN_FORMS,
  },
  {
    name: 'Submissions',
    icon: 'ni ni-collection text-primary',
    path: ROUTES.ADMIN_SUBMISSIONS,
  },
  // {
  //   path: ROUTES.ADMIN_USERS,
  //   name: 'Users',
  //   icon: 'ni ni-badge text-red',
  // },
  {
    path: ROUTES.ADMIN_SUBSCRIBERS,
    name: 'Subscribers',
    icon: 'ni ni-send text-default',
  },
  {
    path: ROUTES.ADMIN_SETTINGS,
    name: 'Settings',
    icon: 'ni ni-ui-04 text-info',
  },
  {
    path: ROUTES.ADMIN_CUSTOM_EMAIL,
    name: 'Custom Email',
    icon: 'ni ni-box-2 text-primary',
  },
];

export const ROUTES_LABELS_MAP = Object.entries(ROUTES).reduce((acc, item) => {
  acc[item[1]] = LINK_LABELS[item[0]];
  return acc;
}, {});
