import React, { Component } from 'react';
//import Select from 'react-select';

import LocalizedLink from '../../components/LocalizedLink';
import AudioItem from '../../components/AudioItem';
import Paginator from '../../components/Paginator';
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { TRANSLATIONS } from '../../constants/translations';
import { singleTypeOptions as audioTypeOptions } from '../../constants/audioTypes';
import { getLabelFromLang } from '../../utils';
import TableFilter from '../../components/TableFilter';
import withTableFilter from '../../HOC/withTableFilter';
import Loader from '../../components/Loader';

const FILTER_SHAPE = {
  type: {
    label: 'Type',
    type: 'react-select',
    initialValue: '',
    options: audioTypeOptions,
  },
  search: {
    label: 'Narrow by word',
    placeholder: 'Any',
    type: 'text',
    initialValue: '',
  },
};

class AudiosPage extends Component {
  componentDidMount() {
    const { lang, list, location, filter } = this.props;

    if (location.query.type) {
      const typeFilter = location.query.type;

      filter.type = typeFilter;

      list({ page: 1, lang, filter: filter });
    } else {
      list({ lang });
    }
  }

  componentDidUpdate(prevProps) {
    const { lang, list, location, filter } = this.props;

    if (prevProps.location.query.type !== location.query.type) {
      const typeFilter = location.query.type;

      filter.type = typeFilter;

      list({ page: 1, lang, filter: this.props.filter });
    }
  }

  getList = ({ page = 1 }) => {
    const { lang, list, filter } = this.props;
    list({ page, lang, filter });
  };

  render() {
    const {
      items,
      allCount,
      loading,
      lang,
      filter,
      onFilterChange,
    } = this.props;

    return (
      <>
        {loading && <Loader type="fixed" />}
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb listClassName="breadcrumb-dark breadcrumb-links pb-2">
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.HOME}>
                  {getLabelFromLang(LINK_LABELS.HOME, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <LocalizedLink to={ROUTES.MATERIALS}>
                  {getLabelFromLang(LINK_LABELS.MATERIALS, lang)}
                </LocalizedLink>
              </BreadcrumbItem>
              <BreadcrumbItem aria-current="page" className="active">
                {getLabelFromLang(LINK_LABELS.AUDIO, lang)}
              </BreadcrumbItem>
            </Breadcrumb>
            <Row className="align-items-stretch">
              <Col lg={4} xs={12}>
                <h1 className="display-2 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.AUDIO, lang)}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-6">
          <Container className="pb-5 no-overflow">
            <TableFilter
              filterValues={filter}
              filterShape={FILTER_SHAPE}
              onChange={onFilterChange}
              onSearch={this.getList}
            />
          </Container>
          <Container className="pb-6">
            <Row>
              {!loading && items && !items.length && (
                <div className="h2">
                  {getLabelFromLang(TRANSLATIONS.NO_CONTENT_MSG, lang)}
                </div>
              )}
              {items &&
                items.map(item => (
                  <Col key={`audio-item-${item.id}`} md={6}>
                    <AudioItem item={item} />
                  </Col>
                ))}
            </Row>
            <Paginator getItems={this.getList} amount={allCount} />
          </Container>
        </section>
      </>
    );
  }
}

export default withTableFilter({
  shape: FILTER_SHAPE,
})(AudiosPage);
