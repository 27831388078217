import React, { Component } from 'react';
import {
  Card,
  CardBody,
  Container,
  UncontrolledCollapse,
  Col,
  Row,
} from 'reactstrap';

import Hr from '../../components/Hr';
import LocalizedLink from '../../components/LocalizedLink';
import { scrollTo } from '../../utils';
import ConsultationForm from '../../components/ConsultationForm';

import {
  beisDin,
  beisDinProcess,
  cost,
  faq,
  sections,
  getItemText,
  consultation,
} from './consts';

export default class ArbitrationPage extends Component {
  scrollTo(e) {
    e.preventDefault();
    const sectionEl = document.getElementById(
      `section-${e.target.attributes['data-section'].value}`,
    );
    const rect = sectionEl.getBoundingClientRect();
    scrollTo(rect.top + window.scrollY);
  }

  renderFaq(faq) {
    const { lang } = this.props;
    const { id } = faq;
    return (
      <div key={`faq-${id}`} className="justify-content-start pb-2">
        <h2 className="text-default pointer" id={`toggle_${id}`}>
          Q: {getItemText(faq, 'q', lang)}
        </h2>
        <UncontrolledCollapse toggler={`#toggle_${id}`}>
          <p className="h2 font-weight-normal mb-4">
            <span className="h2">A:</span> {getItemText(faq, 'a', lang)}
          </p>
        </UncontrolledCollapse>
      </div>
    );
  }

  render() {
    const { lang } = this.props;
    return (
      <>
        {/* Floating Menu */}
        <Container
          className="position-sticky mt-5 top-4 left-0 d-none d-lg-block"
          style={{ zIndex: 2, marginBottom: '-22rem', height: '19rem' }}
        >
          <Row className="justify-content-start">
            <Col lg={3}>
              <Card className="bg-gradient-primary">
                <CardBody>
                  {sections.map(s =>
                    s.label !== 'CONSULTATION' ? (
                      <a
                        key={`section-link-${s.section}`}
                        href="/"
                        data-section={s.section}
                        className="btn-block text-white"
                        onClick={this.scrollTo}
                      >
                        {s.label}
                      </a>
                    ) : null,
                  )}
                  <LocalizedLink
                    className="btn-block text-white"
                    to="/services/documents/58"
                  >
                    ARBITRATION
                  </LocalizedLink>
                  <a
                    className="mt-3 btn btn-secondary btn-block"
                    href="/"
                    data-section={sections[4].section}
                    onClick={this.scrollTo}
                  >
                    CONSULTATION
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* Beis Din */}
        <section
          id="section-beis-din"
          className="header bg-gradient-info pt-5 pb-7"
        >
          <Container>
            <Row className="justify-content-end">
              <Col lg={8} style={{ zIndex: 10 }}>
                <h2 className="display-3 text-white mb-0">
                  {getItemText(beisDin, 'title', lang)}
                </h2>
                <Hr color="white" margin="mt-2" size="2" />
                <div className="h2 mt-0 text-white">
                  {getItemText(beisDin, 'text', lang)}
                </div>
              </Col>
            </Row>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        {/* Process */}
        <section id="section-beis-din-process" className="pt-6 pb-7 bg-default">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>
                <Hr color="white" />
                <h2 className="display-3 text-white mb-0">
                  {getItemText(beisDinProcess, 'title', lang)}
                </h2>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-lg-0 mt--6 pb-6 bg-default">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8}>
                <div className="h2 mt-0 text-white">
                  {getItemText(beisDinProcess, 'text', lang)}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Cost */}
        <section id="section-cost" className="section py-6 bg-white">
          <Container>
            <Row className="justify-content-end mb-3">
              <Col lg={8}>
                <Hr color="default" />
                <h2 className="display-3">
                  {getItemText(cost, 'title', lang)}
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col lg={8}>
                <div className="h2 mt-0">{getItemText(cost, 'text', lang)}</div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* FAQ */}
        <section id="section-faq" className="section pt-6 pb-2">
          <Container>
            <Row className="justify-content-end mb-3">
              <Col lg={8}>
                <Hr color="default" />
                <h2 className="display-3">{getItemText(faq, 'title', lang)}</h2>
                <p className="h3 font-weight-normal mb-2">
                  {getItemText(faq, 'subtitle', lang)}
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-2 pb-6">
          <Container>
            <Row className="justify-content-end">
              <Col lg={8} className="z-index-3">
                {faq.items.map(faq => this.renderFaq(faq))}
              </Col>
            </Row>
          </Container>
        </section>
        {/* CONTACT FORM */}
        <section
          id="section-consultation"
          className="section pt-6 pb-2 bg-white"
        >
          <Container>
            <Row className="justify-content-end mb-3">
              <Col lg={8}>
                <Hr color="default" />
                <h2 className="display-3">
                  {getItemText(consultation, 'title', lang)}
                </h2>
              </Col>
            </Row>
            <Row className="justify-content-end mb-3">
              <Col lg={8} className="z-index-3">
                <ConsultationForm />
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
