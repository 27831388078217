import React, { Component } from 'react';
import classnames from 'classnames';

import LocalizedLink from '../../components/LocalizedLink';
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Carousel,
  CarouselItem,
  Container,
  Col,
  Row,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import Loader from '../../components/Loader';
import SubscribeButton from '../../components/SubscribeBtn';
import classes from './BusinessWeeklyPage.module.scss';
import { TRANSLATIONS } from '../../constants/translations';
import { ROUTES } from '../../constants/routes';
import { LINK_LABELS } from '../../constants/labels';
import { bwTypesMap, labels } from '../../constants/document';
import { FILE_URL } from '../../constants/files';
import { getTextAlign, getLabelFromLang, tippyContent } from '../../utils';
import ShareBtn from '../../components/ShareBtn';
import gematriya from 'gematriya';
export default class BusinessWeeklyPage extends Component {
  constructor(props) {
    super(props);
    this.animating = false;
    this.state = {
      bwItem: { publications: [] },
      activeIndex: 0,
      fromProps: {},
    };
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  static getDerivedStateFromProps(nextProps, state) {
    const {
      location: {
        query: { selected },
      },
      match: {
        params: { id },
      },
      recent,
      item,
    } = nextProps;
    const newState = {
      fromProps: {
        id: state.fromProps.id,
        item: state.fromProps.item,
        recent: state.fromProps.recent,
        selected: state.fromProps.selected,
      },
    };

    if (!id && recent.id && !state.fromProps.recent) {
      if (selected !== undefined) newState.activeIndex = parseInt(selected);
      newState.bwItem = recent;
      newState.fromProps.recent = recent;
    }

    if (id && item.id && id === item.id && !state.bwItem.id) {
      if (selected !== undefined) newState.activeIndex = parseInt(selected);
      newState.bwItem = item;
      newState.fromProps.item = item;
    }

    if (selected !== state.fromProps.selected) {
      if (selected !== undefined) {
        newState.activeIndex = parseInt(selected);
        newState.fromProps.selected = selected;
      }
    }

    if (id !== state.fromProps.id) {
      newState.fromProps.id = id;
      delete newState.fromProps.item;
      delete newState.fromProps.recent;
      newState.bwItem = { publications: [] };
    }

    return newState;
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.props.getItem(id);
    } else {
      this.props.getRecent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fromProps: { id },
    } = this.state;
    const {
      fromProps: { id: oldId },
    } = prevState;
    if (oldId !== id) {
      if (id) {
        this.setState({ bwItem: { publications: [] } });
        this.props.getItem(id);
      } else {
        this.props.getRecent();
      }
    }

    const footnotesArr = document.querySelectorAll('span.footnote');
    footnotesArr.forEach(el =>
      el.addEventListener('click', e => {
        e.preventDefault();
        const dataRef = el.getAttribute('data-ref');

        const ftn = document.getElementById(dataRef.substring(1));

        ftn.classList.remove('highlight');

        void ftn.offsetWidth;

        ftn.classList.add('highlight');
        ftn.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }),
    );
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    let { activeIndex } = this.state;
    const {
      bwItem: { publications },
    } = this.state;
    this.setState({
      activeIndex: publications.length < ++activeIndex + 1 ? 0 : activeIndex,
    });
  }

  prev(e) {
    if (this.animating) return;
    let { activeIndex } = this.state;
    const {
      bwItem: { publications },
    } = this.state;
    this.setState({
      activeIndex: 0 > --activeIndex ? publications.length - 1 : activeIndex,
    });
  }

  goToIndex(activeIndex) {
    if (this.animating) return;
    this.setState({ activeIndex });
  }

  renderActions = () => {
    const { selectedLang, location } = this.props;
    const {
      bwItem: { files = { [selectedLang]: {} }, mainTitle },
    } = this.state;
    return (
      <>
        <a
          href={`${FILE_URL}${files[selectedLang] && files[selectedLang].url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0"
        >
          <span className="btn-inner--icon">
            <i className="ni ni-cloud-download-95" />
          </span>
          <span className="btn-inner--text">
            {getLabelFromLang(TRANSLATIONS.DOWNLOAD, selectedLang)} PDF
          </span>
        </a>

        <ShareBtn contentType="BUSINESS_WEEKLY" title={mainTitle} />

        <a
          href={
            selectedLang === 'en'
              ? `${location.pathname.replace('en', 'he')}`
              : `${location.pathname.replace('he', 'en')}`
          }
          className="btn btn-block btn-lg btn-outline-primary btn-icon ml-0 mb-4"
        >
          <span className="btn-inner--icon">
            <i className="ni ni-collection" />
          </span>
          <span className="btn-inner--text">
            {selectedLang === 'en'
              ? getLabelFromLang(LINK_LABELS.BUSINESS_WEEKLY, 'he')
              : getLabelFromLang(LINK_LABELS.BUSINESS_WEEKLY, 'en')}
          </span>
        </a>
      </>
    );
  };

  renderBreadcrumbs(lang, number, mainTitle) {
    const { selectedLang, defaultLang } = this.props;
    const langData = (lang && lang[selectedLang]) || {};
    const defaultLangData = (lang && lang[defaultLang]) || {};
    const breadcrumbItems = [
      <BreadcrumbItem key="home">
        <LocalizedLink to={ROUTES.HOME}>
          {getLabelFromLang(LINK_LABELS.HOME, selectedLang)}
        </LocalizedLink>
      </BreadcrumbItem>,
      <BreadcrumbItem key="bw">
        <LocalizedLink to={ROUTES.BUSINESS_WEEKLY}>
          {getLabelFromLang(LINK_LABELS.BUSINESS_WEEKLY, selectedLang)}
        </LocalizedLink>
      </BreadcrumbItem>,
      <BreadcrumbItem key="page" aria-current="page" className="active">
        {`#${langData.number || defaultLangData.number} - ${langData.title ||
          defaultLangData.title}`}
      </BreadcrumbItem>,
    ];

    return selectedLang !== 'he' ? breadcrumbItems : breadcrumbItems.reverse();
  }

  renderItem(item) {
    const { selectedLang } = this.props;
    const isHE = selectedLang === 'he';

    const {
      bwItem: { publishedAt },
    } = this.state;
    if (!item.subType) return null;

    const isMoneyMatters = item.subType === 'MONEY_MATTERS';

    return (
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
        key={`nl-${item.subType.toLowerCase()}`}
        className={classes.item}
      >
        <CardBody className="business-weekly-card-body pt-lg-0">
          {isMoneyMatters &&
            (!isHE ? (
              <div className={classes.mmCatContainer}>
                <span className={classes.mmCatCategory}>
                  {item.lang[selectedLang].category} #
                  {item.lang[selectedLang].serialNumber}
                </span>
                {!!item.lang[selectedLang].subcategory && (
                  <span className={classes.mmCatSubcategory}>
                    &nbsp;- {item.lang[selectedLang].subcategory}
                  </span>
                )}
              </div>
            ) : (
              <div
                className={classes.mmCatContainer}
                style={{ textAlign: 'right' }}
              >
                <span className={classes.mmCatCategory}>
                  {item.lang[selectedLang].category}{' '}
                  {item.lang[selectedLang].serialNumber}#
                </span>
                {!!item.lang[selectedLang].subcategory && (
                  <span className={classes.mmCatSubcategory}>
                    &nbsp;- {item.lang[selectedLang].subcategory}
                  </span>
                )}
              </div>
            ))}

          <CardTitle
            className={classnames('h1 mb-0', {
              'text-right': isHE,
            })}
          >
            {item.lang[selectedLang].title}
          </CardTitle>

          <div
            className={classnames('h3 text-muted mb-0', {
              'text-right': isHE,
            })}
          >
            {
              getLabelFromLang(TRANSLATIONS.AUTHOR_BW, selectedLang)[
                item.subType
              ]
            }
          </div>

          {publishedAt && (
            <div
              className={classnames('h4 font-weight-normal text-muted', {
                'text-right': isHE,
              })}
            >
              {new Date(publishedAt).toDateString()}
            </div>
          )}

          {item.subType === 'MONEY_MATTERS' && selectedLang !== 'he' && (
            <div className="h3 font-weight-normal mt-4 mb-0 pb-2 border-bottom basedOn">
              Based on the writings of Rav Chaim Kohn,
              <span className="font-italic"> שליט''א</span>
            </div>
          )}

          <div
            className={classnames('mt-4 card-text', {
              'text-left': !isHE,
              'text-right': isHE,
            })}
            dangerouslySetInnerHTML={{
              __html: tippyContent(item.lang[selectedLang].content),
            }}
          />
        </CardBody>
      </CarouselItem>
    );
  }

  render() {
    const { loading, selectedLang } = this.props;
    const {
      activeIndex,
      bwItem: {
        publications,
        publishedAt,
        number,
        lang,
        mainTitle,
        author = TRANSLATIONS.AUTHOR_DEFAULT[selectedLang.toUpperCase()],
      },
    } = this.state;

    const isHE = selectedLang === 'he';
    const langData = (lang && lang[selectedLang]) || {};

    return (
      <>
        {loading && <Loader type="fixed" />}
        <section className="bg-gradient-info py-5">
          <Container>
            <Breadcrumb
              listClassName={classnames(
                'breadcrumb-dark breadcrumb-links pb-2',
                {
                  'justify-content-start': !isHE,
                  'justify-content-end': isHE,
                },
              )}
            >
              {this.renderBreadcrumbs(lang, number, mainTitle)}
            </Breadcrumb>
            <Row
              className={classnames('align-items-stretch', {
                'flex-row-reverse': isHE,
              })}
            >
              <Col
                lg={4}
                xs={12}
                className={classnames('mb-4 mb-lg-0', {
                  'text-right': isHE,
                })}
              >
                <h1 className="display-3 text-white mb-0">
                  {getLabelFromLang(LINK_LABELS.BUSINESS_WEEKLY, selectedLang)}
                </h1>
                {langData.number && (
                  <p className="text-white display-3 font-weight-500 mb-0">
                    # {isHE ? gematriya(langData.number) : langData.number}
                  </p>
                )}
              </Col>
              <Col
                xs={12}
                md={5}
                className={classnames({
                  'text-left': !isHE,
                  'text-right': isHE,
                })}
              >
                <h2 className="text-white display-2 mb-0 font-weight-500 pl-lg-4">
                  {lang && lang[selectedLang]
                    ? lang[selectedLang].title
                    : mainTitle}
                </h2>
                <p
                  className={classnames('h4 text-white pl-lg-4 d-flex', {
                    'flex-row-reverse': isHE,
                  })}
                >
                  <span>{author}</span>
                  <span>&nbsp;|&nbsp;</span>
                  <span>
                    {publishedAt && new Date(publishedAt).toDateString()}
                  </span>
                </p>
              </Col>
              <Col
                lg={3}
                md={7}
                xs={12}
                className={classnames(
                  'align-self-center mt-5 mt-md-0 text-center',
                  {
                    'text-md-left': isHE,
                    'text-md-right': !isHE,
                  },
                )}
              >
                <LocalizedLink
                  to={ROUTES.BUSINESS_WEEKLY_ARCHIVE}
                  className="btn btn-default btn-lg px-4"
                >
                  <span className="btn-inner--text mr-1 text-uppercase">
                    {getLabelFromLang(TRANSLATIONS.SEE_ARCHIVE, selectedLang)}
                  </span>
                </LocalizedLink>
              </Col>
            </Row>
          </Container>
        </section>
        <Container className="overflow-visible">
          <Row
            className={classnames('mt-5', {
              'flex-row-reverse': isHE,
            })}
          >
            <Col
              lg={4}
              xs={12}
              className={`top-4 align-self-start ${classes.controls}`}
            >
              <div>
                <Card className="overflow-hidden mb-3 mb-sm-4">
                  <CardBody className="p-0 d-flex d-lg-block">
                    {publications.map(
                      (item, i) =>
                        item.subType && (
                          <div
                            key={`nl-indicator-${item.subType.toLowerCase()}`}
                            className={`m-0 ${
                              i === activeIndex
                                ? 'bg-gradient-primary'
                                : 'bg-transparent'
                            } ${classes.indicator}`}
                            onClick={() => this.goToIndex(i)}
                          >
                            <h5
                              className={`m-0 h4 w-100 text-center text-uppercase ${
                                i === activeIndex ? 'text-white' : ''
                              } ${getTextAlign(selectedLang, 'lg')}`}
                            >
                              {getLabelFromLang(
                                labels[bwTypesMap[item.subType]],
                                selectedLang,
                              )}
                            </h5>
                          </div>
                        ),
                    )}
                  </CardBody>
                  <CardFooter className="p-0 mt-lg-8">
                    <SubscribeButton />
                  </CardFooter>
                </Card>
                <div className="d-none d-sm-block">{this.renderActions()}</div>
              </div>
            </Col>
            <Col
              lg={8}
              xs={12}
              className="mb-4 mb-sm-5 mb-lg-0 mt-2 mt-md-0"
              dir={`${isHE ? 'rtl' : 'ltr'}`}
            >
              <Carousel
                next={this.next}
                previous={this.prev}
                ride={undefined}
                interval={null}
                activeIndex={activeIndex}
              >
                {publications.map(this.renderItem)}
              </Carousel>
            </Col>
            <Col xs={12} className="d-block d-sm-none">
              {this.renderActions()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
